import React from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from "prop-types";


class AgeSelection extends React.Component {

    constructor(props) {
        super(props)
        this.ages = props.ages || [
            "0-6 mois",
            "6-12 mois",
            "12-18 mois",
            "18-24 mois",
            "2 ans",
            "3 ans",
            "4 ans",
            "5 ans",
            "6 ans et plus",
        ]
        this.state = {
            age: 0
        }
    }

    componentDidMount() {
        this.props.handleSetAge(this.ages[this.state.age])
    }

    render () {
        return (
            <Form.Group className={this.props.className}>
                <Form.Label>Age de l'enfant</Form.Label>
                <Form.Control as="select" value={this.state.age} custom 
                onChange={
                    (event) => {this.setState({age:event.target.value}); this.props.handleSetAge(this.ages[event.target.value])}}
                    >
                {
                    this.ages.map((ageValue, index) => {
                        return <option key={"age_"+ index} value={index}>{ageValue}</option>
                    })
                }
                </Form.Control>
            </Form.Group>
        )
    }
}

AgeSelection.propTypes = {
    handleSetAge: PropTypes.func.isRequired
}


export default AgeSelection