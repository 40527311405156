import React, {useState} from 'react'
import {navigate} from 'gatsby'
import {Alert, Button, Container, Form, Modal} from 'react-bootstrap'
import Layout from "../components/layout"
import queryString from 'query-string'
import axios from 'axios'
import ChildAgeSelection from "../components/ChildAgeSelection";

export default ({location}) => {

    const [state, setState] = useState({
        dirty: false,
        validated: false,
        error_msg: '',
        info_msg: '',
        card_id: queryString.parse(location.search).gift_id,
        isUnlimited: (queryString.parse(location.search).unlimited || 'false') === 'true',
        code: '',
        email: '',
        name: '',
        child_name: '',
        child_age: '',
        phone: ''
    });

    function handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            dirty: true,
            [name]: value
        })
    }

    function handleSubmit(event) {
        setState({
            ...state,
            dirty: false,
            error_msg: ''
        })
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setState({...state, validated: true})
            return
        }

        setState({...state, validated: true, dirty: false, error_msg: ''});

        axios({method: 'post', url: '/.netlify/functions/cardActivate', data: state}).then(response => {
            setState({...state, info_msg: "Votre carte d'accès a été activée."})
        }).catch((err) => {
            console.log(err.response)
            if (err.response) {
                if (err.response.status === 500) {
                    alert("Une erreur technique est survenue, veuillez contacter notre équipe afin qu'on puisse résoudre le problème dans les meilleurs délais")
                } else if (err.response.status === 400) {
                    setState({...state, error_msg: err.response.data.msg})
                }
            }
        })
    }

    return (
        <Layout>
            <Container className="text-center">
                <Modal show={true} onHide={() => navigate('/')} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Activer votre carte d'accès</Modal.Title>
                    </Modal.Header>
                    <Form
                        validated={state.validated}
                        onSubmit={handleSubmit}
                        className="border rounded px-4 needs-validation" noValidate>

                        <Form.Group className="my-2 mx-2">
                            <Form.Label>Votre nom et prénom *</Form.Label>
                            <Form.Control
                                name="name"
                                onChange={handleChange}
                                value={state.name}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="my-2 mx-2">
                            <Form.Label>Votre adresse email *</Form.Label>
                            <Form.Control
                                name="email"
                                onChange={handleChange}
                                value={state.email}
                                type="email"
                                pattern=".+@.+\..+"
                                required
                            />
                        </Form.Group>

                        {
                            state.isUnlimited ? (
                                <>
                                    <Form.Group className="my-2 mx-2">
                                        <Form.Label>Prénom de l'enfant</Form.Label>
                                        <Form.Control
                                            name="child_name"
                                            onChange={handleChange}
                                            value={state.child_name}
                                            required
                                        />
                                    </Form.Group>
                                    <ChildAgeSelection
                                        className="my-2 mx-2"
                                        handleSetAge={(age) => setState({...state, child_age: age})}/>
                                </>
                            ) : ''

                        }

                        <Form.Group className="my-2 mx-2">
                            <Form.Label>Numéro de téléphone *</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Numéro de téléphone"
                                pattern="([0-9]{2}[. -]?){5}"
                                name="phone"
                                value={state.phone}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="my-2 mx-2">
                        <Form.Label>
                            Code de confirmation que vous avez reçu dans l'email d'activation de votre carte d'accès
                        </Form.Label>
                        <Form.Control
                            name="code"
                            onChange={handleChange}
                            value={state.code}
                            required
                        />
                        </Form.Group>


                        <Button className={"btn btn-green my-4"} type="submit" disabled={!state.dirty}>
                           Activer ma carte d'accès
                        </Button>
                    </Form>
                    <Alert variant="danger" className={state.error_msg ? '' : 'd-none'}>{state.error_msg}</Alert>
                    <Alert variant="green" className={state.info_msg ? '' : 'd-none'}>{state.info_msg}</Alert>

                </Modal>
            </Container>
        </Layout>
    )
}